<template>
    <div class="p-5 text-gray-200">
        <div class="flex items-center justify-between mb-5">
            <div class="flex items-center">
                <calendar @date="onSelectDate"></calendar>
            </div>
        </div>
        <div class="grid lg:grid-cols-2 gap-5">
            <div class="bg-red-500 p-2 rounded-sm">
                <div class="mb-2 font-semibold text-lg">SUHU RUANGAN</div>
                <canvas id="temp"></canvas>
            </div>
            <div class="bg-gray-300 p-2 rounded-sm">
                <div class="mb-2 text-gray-700 font-semibold text-lg">SUHU AIR SIKULASI</div>
                <canvas id="waterTemp"></canvas>
            </div>
            <div class="bg-green-500 p-2 rounded-sm">
                <div class="mb-2 font-semibold text-lg">TDS</div>
                <canvas id="tds"></canvas>
            </div>
            <div class="bg-yellow-500 p-2 rounded-sm">
                <div class="mb-2 font-semibold text-lg">HUMIDITY</div>
                <canvas id="hum"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import calendar from '../components/calendar.vue'
import axios from 'axios'
export default {
    components: {calendar},
    data () {
        return {
            temp: [],
            waterTemp: [],
            tds: [],
            hum: [],
            time: []
        }
    },
    mounted () {
        const date = new Date()
            let Y = date.getFullYear()
            let M = date.getMonth() + 1
            let D = date.getDate()
            M = checkTime(M)
            D = checkTime(D)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
        let d = `${Y}-${M}-${D}`
        let ctxHeigh = 100
        let app = this
        const options = {
            responsive: true,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    backgroundColor: 'black',
                    displayColors: false,
                    titleAlign: 'center',
                    bodyAlign: 'center'
                }
            },
            layout: {
                padding: {
                    left: -1,
                    bottom: -4
                }
            },
        }
        const scalesGray = {
            scales: {
                x: {
                    labels: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                },
                y: {
                    ticks: {
                        display: true,
                        font: {
                            size: 10
                        },
                        color: 'rgb(113,113,122, 0.8)'
                    },
                    grid: {
                        display: true,
                        drawBorder: false,
                        color: 'rgb(113,113,122, 0.2)'
                    }
                }
            }
        }
        const scalesWhite = {
            scales: {
                x: {
                    labels: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                },
                y: {
                    ticks: {
                        display: true,
                        font: {
                            size: 10
                        },
                        color: 'rgb(244,244,245, 0.8)'
                    },
                    grid: {
                        display: true,
                        drawBorder: false,
                        color: 'rgb(244,244,245, 0.2)'
                    }
                }
            }
        }
        var ctxTemp = document.getElementById('temp')
        ctxTemp.height = ctxHeigh
        window.myTemp = new Chart(ctxTemp, {
            type: 'line',
            data: {
                labels: app.time,
                datasets: [
                    {
                        data: app.temp,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
        var ctxWaterTemp = document.getElementById('waterTemp')
        ctxWaterTemp.height = ctxHeigh
        window.myWaterTemp = new Chart(ctxWaterTemp, {
            type: 'line',
            data: {
                labels: app.time,
                datasets: [
                    {
                        data: app.waterTemp,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesGray
            }
        })
        var ctxTds = document.getElementById('tds')
        ctxTds.height = ctxHeigh
        window.myTds = new Chart(ctxTds, {
            type: 'line',
            data: {
                labels: app.time,
                datasets: [
                    {
                        data: app.tds,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
        var ctxHum = document.getElementById('hum')
        ctxHum.height = ctxHeigh
        window.myHum = new Chart(ctxHum, {
            type: 'line',
            data: {
                labels: app.time,
                datasets: [
                    {
                        data: app.hum,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
        this.onSelectDate(d, 'Hari ini')
    },
    methods: {
        onSelectDate (d, l) {
            this.temp.splice(0, this.temp.length)
            this.waterTemp.splice(0, this.waterTemp.length)
            this.tds.splice(0, this.tds.length)
            this.hum.splice(0, this.hum.length)
            this.time.splice(0, this.time.length)
            axios.get('/statistik', {
                params: {
                    date: d,
                    label: l
                }
            })
            .then (res => {
                for (let i = 0; i < res.data.length; i++) {
                    const el = res.data[i];
                    this.temp.push(el.temp)
                    this.waterTemp.push(el.waterTemp)
                    this.tds.push(el.tds)
                    this.hum.push(el.hum)
                    this.time.push(el.created_at)
                }
                this.$nextTick(() => {
                    window.myTemp.update()
                    window.myWaterTemp.update()
                    window.myTds.update()
                    window.myHum.update()
                })
            })
        }
    }
}
</script>