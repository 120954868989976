<template>
    <div class="p-5 text-gray-400">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <calendar @date="onSelectDate"></calendar>
            </div>
            <div>
                <button @click="onDownload" class="bg-green-500 h-9 px-5 rounded-sm text-green-100">DOWNLOAD</button>
            </div>
        </div>
        <div class="mt-5 rounded-sm border overflow-hidden border-gray-700">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="px-3 py-3 bg-gray-800">Tanggal</th>
                        <th class="px-3 py-3 bg-gray-800 text-right">Suhu Ruangan</th>
                        <th class="px-3 py-3 bg-gray-800 text-right">Suhu Air Sirkulasi</th>
                        <th class="px-3 py-3 bg-gray-800 text-right">TDS</th>
                        <th class="px-3 py-3 bg-gray-800 text-right">Humidity</th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-700">
                    <tr v-for="(log, i) in loggers" :key="i">
                        <td class="px-3 py-1.5">{{log.created_at}}</td>
                        <td class="px-3 py-1.5 text-right">{{log.temp}}℃</td>
                        <td class="px-3 py-1.5 text-right">{{log.waterTemp}}℃</td>
                        <td class="px-3 py-1.5 text-right">{{log.tds}} mg/l</td>
                        <td class="px-3 py-1.5 text-right">{{log.hum}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5 text-right">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import Calendar from '../components/calendar.vue'
import axios from 'axios'
import Paginate from '../components/paginate.vue'
export default {
    components: {Calendar, Paginate},
    data () {
        return {
            loggers: [],
            pages: '',
            date: '',
            label: 'Hari ini'
        }
    },
    mounted () {
        this.getDate()
    },
    methods: {
        getData (e) {
            window.scroll(0,0)
            axios.get('/data', {
                params: {
                    page: e,
                    date: this.date,
                    label: this.label
                }
            })
            .then(res => {
                this.pages = res.data.pages
                this.loggers = res.data.data
            })
        },
        onSelectDate (d, l) {
            this.date = d 
            this.label = l
            this.getData()
        },
        onDownload () {
            axios.get('/download', {
                params: {
                    date: this.date,
                    label: this.label
                },
                responseType: 'blob'
            })
            .then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'laporan.xlsx'); //or any other extension
				document.body.appendChild(link);
				link.click();
			})
        },
        getDate () {
            const date = new Date()
            let Y = date.getFullYear()
            let M = date.getMonth() + 1
            let D = date.getDate()
            M = checkTime(M)
            D = checkTime(D)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            this.date = `${Y}-${M}-${D}`
            this.getData()
        }
    }
}
</script>