import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import {io} from 'socket.io-client'

axios.defaults.baseURL = 'https://syscona.onrender.com'
import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
  connection: io('https://syscona.onrender.com', {
    auth: {
      token: 'web'
    }
  }),
}))

window.$ = window.jQuery =  require('jquery')
require('./datepicker.js')

import './datepicker.css'
import './index.css'
import './assets/icons/icomoon/styles.min.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
