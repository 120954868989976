<template>
    <div>
        <div>
            <button @click.prevent="show = !show" type="button" class="bg-gray-700 border border-gray-600 text-gray-300 relative rounded-sm h-9 focus:outline-none w-52">
                <div class="flex items-center h-full relative">
                    <div class="bg-gray-800 bg-opacity-50 h-full w-9 rounded-l-sm flex justify-center items-center">
                        <i class="icon-calendar3"></i>
                    </div>
                    <div class="px-3 opacity-75 truncate">
                       {{date_label}}
                    </div>
                </div>
                <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-gray-400"></div>
            </button>
        </div>
        <div v-show="show" class="bg-gray-700 z-10 border border-gray-600 bg-opacity-60 text-gray-300 mt-1 rounded-sm shadow-lg w-96 absolute overflow-hidden backdrop-filter backdrop-blur-sm">
            <ul class="relative">
                <li @click="onSelect('hari ini')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='hari ini'}" class="px-5 py-2 hover:bg-gray-600 hover:bg-opacity-75 cursor-pointer">Hari ini</li>
                <li @click="onSelect('kemarin')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='kemarin'}" class="px-5 py-2 hover:bg-gray-600 hover:bg-opacity-75 cursor-pointer">Kemarin</li>
                <li @click="onSelect('7 hari terakhir')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='7 hari terakhir'}" class="px-5 py-2 hover:bg-gray-600 hover:bg-opacity-75 cursor-pointer">7 Hari terakhir</li>
                <li @click="onSelect('30 hari terakhir')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='30 hari terakhir'}" class="px-5 py-2 hover:bg-gray-600 hover:bg-opacity-75 cursor-pointer">30 Hari terakhir</li>
                <li @click="onSelect('bulan ini')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='bulan ini'}" class="px-5 py-2 hover:bg-gray-600 hover:bg-opacity-75 cursor-pointer">Bulan ini</li>
                <div class="border-b border-gray-600 w-32 my-2"></div>
                <li @click="onSelect('per hari')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='per hari'}" class="pl-5 pr-3 py-2 hover:bg-gray-600 hover:bg-opacity-75 flex items-center justify-between w-32 cursor-pointer">
                    <div>Per Hari</div>
                    <div><i class="icon-arrow-right22"></i></div>
                </li>
                <li @click="onSelect('per bulan')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='per bulan'}" class="pl-5 pr-3 py-2 hover:bg-gray-600 hover:bg-opacity-75 flex items-center justify-between w-32 cursor-pointer">
                    <div>Per Bulan</div>
                    <div><i class="icon-arrow-right22"></i></div>
                </li>
                <li @click="onSelect('per tahun')" :class="{'bg-gray-600 bg-opacity-75 text-cyan-500 font-medium': active=='per tahun'}" class="pl-5 pr-3 py-2 hover:bg-gray-600 hover:bg-opacity-75 flex items-center justify-between w-32 cursor-pointer">
                    <div>Per Tahun</div>
                    <div><i class="icon-arrow-right22"></i></div>
                </li>
                <div id="date" v-show="showCalendar" class="absolute bg-gray-600 bg-opacity-75 top-0 left-32 w-64 h-full">
                    <div v-show="active == 'per hari'">
                        <div class="per-day"></div>
                    </div>
                    <div v-show="active == 'per bulan'">
                        <div class="per-month"></div>
                    </div>
                    <div v-show="active == 'per tahun'">
                        <div class="per-year"></div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    data () {
        return {
            show: false,
            showCalendar: false,
            date: '',
            date_label: '',
            active: '',
        }
    },
    mounted () {

        $('#date').click(function(e) {
            e.stopPropagation()
        })
        document.body.addEventListener('click', this.closeMenu)
        let today = new Date()
        let YY = today.getFullYear()
        let MM = today.getMonth()+1
        let DD = today.getDate()
        this.date = `${YY}-${MM}-${DD}`
        this.date_label = 'Hari ini'
        this.daterange = 'day'
        this.active = 'hari ini'
        let app = this

        $('.per-day').datepicker({
            view: 'days',
            minView: 'days',
            dateFormat: 'yyyy-mm-dd',
            clearButton: false,
            onSelect: function (v) {
                console.log($('.per-day').datepicker());
                app.date = v
                app.date_label ='Per Hari'
                app.$nextTick(() => {
                    app.show = false
                    if(v) {
                        app.$emit('date', app.date, app.date_label)
                    }
                })
            },
        })
        $('.per-month').datepicker({
            view: 'months',
            minView: 'months',
            dateFormat: 'yyyy-mm',
            onSelect: function (v) {
                app.date = v
                app.date_label ='Per Bulan'
                app.$nextTick(() => {
                    app.show = false
                    if(v) {
                        app.$emit('date', app.date, app.date_label)
                    }
                })
            },
        })
        $('.per-year').datepicker({
            view: 'years',
            minView: 'years',
            dateFormat: 'yyyy',
            onSelect: function (v) {
                app.date = v
                app.date_label ='Per Tahun'
                app.$nextTick(() => {
                    app.show = false
                    if(v) {
                        app.$emit('date', app.date, app.date_label)
                    }
                })
            },
        })
    },
    destroyed () {
        document.body.removeEventListener('click', this.closeMenu)
    },
    methods: {
        closeMenu ($event) {
            if(!$event || !this.$el.contains($event.target)) {
                this.show = false
            }
        },
        onSelect (val) {
            const today = new Date()
            let YY = today.getFullYear()
            let MM = today.getMonth()+1
            let DD = today.getDate()
            MM = checkTime(MM)
            DD = checkTime(DD)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            this.active = val
            if(val == 'hari ini') {
                this.showCalendar = false
                this.date = `${YY}-${MM}-${DD}`
                this.date_label = 'Hari ini'
                this.$nextTick(() => {
                    this.show = false
                    this.$emit('date', this.date, this.date_label)
                })
            }
            if(val == 'kemarin') {
                this.showCalendar = false
                this.date = `${YY}-${MM}-${DD}`
                this.date_label = 'Kemarin'
                this.$nextTick(() => {
                    this.show = false
                    this.$emit('date', this.date, this.date_label)
                })
            }
            if(val == '7 hari terakhir') {
                this.showCalendar = false
                this.date = `${YY}-${MM}-${DD}`
                this.date_label = '7 Hari terakhir'
                this.$nextTick(() => {
                    this.show = false
                    this.$emit('date', this.date, this.date_label)
                })
            }
            if(val == '30 hari terakhir') {
                this.showCalendar = false
                this.date = `${YY}-${MM}-${DD}`
                this.date_label = '30 Hari terakhir'
                this.$nextTick(() => {
                    this.show = false
                    this.$emit('date', this.date, this.date_label)
                })
            }
            if(val == 'bulan ini') {
                this.showCalendar = false
                this.date = `${YY}-${MM}`
                this.date_label = 'Bulan ini'
                this.$nextTick(() => {
                    this.show = false
                    this.$emit('date', this.date, this.date_label)
                })
            }
            if(val == 'per hari') {
                this.showCalendar = true
            }
            if(val == 'per bulan') {
                this.showCalendar = true
            }
            if(val == 'per tahun') {
                this.showCalendar = true
            }
        }
    },
}
</script>

<style>
    .date-picker .datepicker {
        border: none !important;        
    }
</style>