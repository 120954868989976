<template>
    <div class="p-5 text-gray-50">
        <div class="grid lg:grid-cols-12 gap-5">
            <div class="lg:col-span-4 border border-gray-500 rounded-sm p-3">
                <div class="border mb-3 border-gray-700 rounded-sm px-2 py-1 flex items-center justify-between">
                    <div class="text-gray-400">Device Status</div> 
                    <div v-if="device" class="flex items-center">
                        <div class="h-3 w-3 bg-green-500 border border-green-300 rounded-full mr-2"></div>
                        ONLINE
                    </div>
                    <div v-else class="flex items-center">
                        <div class="h-3 w-3 bg-gray-600 border border-gray-500 rounded-full mr-2"></div>
                        OFFLINE
                    </div>
                </div>
                <div class="bg-gray-700 rounded-sm p-5">
                    <div class="text-center text-lg font-semibold">POMPA</div>
                    <div class="text-center mt-2">
                        <i  :class="{'animate-spin text-green-500': loggers.pompa}" class="icon-spinner4 text-red-500" style="font-size: 100px"></i>
                    </div>
                </div>
                <div class="text-center my-5">
                    <img src="@/assets/logo.png" alt="">
                    <div class="mt-2 text-gray-400">Syscona Integrasi @2022</div>
                </div>
            </div>
            <div class="lg:col-span-8">
                <div class="grid md:grid-cols-2 gap-5">
                    <!-- TEMPERATURE -->
                    <div class="col-span-1 bg-red-500 pl-1 pr-3 py-3 rounded-sm">
                        <div class="flex items-center justify-between">
                            <div>
                                <svg class="svg-icon" style="width: 35px; height: 35px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M554.666667 646.4 554.666667 384c0-23.466667-19.2-42.666667-42.666667-42.666667s-42.666667 19.2-42.666667 42.666667l0 262.4c-49.066667 17.066667-85.333333 64-85.333333 121.6 0 70.4 57.6 128 128 128s128-57.6 128-128C640 712.533333 603.733333 665.6 554.666667 646.4zM640 597.333333 640 170.666667c0-70.4-57.6-128-128-128s-128 57.6-128 128l0 426.666667c-51.2 38.4-85.333333 100.266667-85.333333 170.666667 0 117.333333 96 213.333333 213.333333 213.333333 117.333333 0 213.333333-96 213.333333-213.333333C725.333333 697.6 691.2 635.733333 640 597.333333zM512 938.666667c-93.866667 0-170.666667-76.8-170.666667-170.666667 0-64 34.133333-117.333333 85.333333-147.2L426.666667 170.666667c0-46.933333 38.4-85.333333 85.333333-85.333333s85.333333 38.4 85.333333 85.333333l0 450.133333c51.2 29.866667 85.333333 85.333333 85.333333 147.2C682.666667 861.866667 605.866667 938.666667 512 938.666667z"  /></svg>
                            </div>
                            <div class="text-lg">Suhu Ruangan</div>
                        </div>
                        <div class="flex items-center justify-center my-5">
                            <div class="text-4xl font-semibold">{{loggers.temp.toFixed(2)}}℃</div>
                        </div>
                        <div class="flex items-center text-gray-100">
                            <div class="font-medium">SAAT INI</div>
                            <div class="space-x-3.5 flex text-xs ml-auto">
                                <div class="border-b-2 border-gray-100">AVG: {{temp.avg}}</div>
                                <div class="border-b-2 border-gray-100">LOW: {{temp.low}}</div>
                                <div class="border-b-2 border-gray-100">HIGH: {{temp.high}}</div>
                            </div>
                        </div>
                        <canvas id="temp"></canvas>
                    </div>

                    <!-- WATER TEMP -->
                    <div class="col-span-1 bg-gray-300 pl-1 pr-3 py-3 rounded-sm text-gray-600">
                        <div class="flex items-center justify-between">
                            <div class="relative">
                                <div>
                                    <svg class="svg-icon" style="width: 35px; height: 35px;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M554.666667 646.4 554.666667 384c0-23.466667-19.2-42.666667-42.666667-42.666667s-42.666667 19.2-42.666667 42.666667l0 262.4c-49.066667 17.066667-85.333333 64-85.333333 121.6 0 70.4 57.6 128 128 128s128-57.6 128-128C640 712.533333 603.733333 665.6 554.666667 646.4zM640 597.333333 640 170.666667c0-70.4-57.6-128-128-128s-128 57.6-128 128l0 426.666667c-51.2 38.4-85.333333 100.266667-85.333333 170.666667 0 117.333333 96 213.333333 213.333333 213.333333 117.333333 0 213.333333-96 213.333333-213.333333C725.333333 697.6 691.2 635.733333 640 597.333333zM512 938.666667c-93.866667 0-170.666667-76.8-170.666667-170.666667 0-64 34.133333-117.333333 85.333333-147.2L426.666667 170.666667c0-46.933333 38.4-85.333333 85.333333-85.333333s85.333333 38.4 85.333333 85.333333l0 450.133333c51.2 29.866667 85.333333 85.333333 85.333333 147.2C682.666667 861.866667 605.866667 938.666667 512 938.666667z"  /></svg>
                                </div>
                                <div class="top-1.5 absolute right-0">
                                    <i class="icon-air"></i>
                                </div>
                            </div>
                            <div class="text-lg">Suhu Air Sirkulasi</div>
                        </div>
                        <div class="flex items-center justify-center my-5">
                            <div class="text-4xl font-semibold">{{loggers.waterTemp.toFixed(2)}}℃</div>
                        </div>
                        <div class="flex items-center text-gray-600">
                            <div class="font-medium">SAAT INI</div>
                            <div class="space-x-3.5 flex text-xs ml-auto">
                                <div class="border-b-2 border-gray-600">AVG: {{temp.avg}}</div>
                                <div class="border-b-2 border-gray-600">LOW: {{temp.low}}</div>
                                <div class="border-b-2 border-gray-600">HIGH: {{temp.high}}</div>
                            </div>
                        </div>
                        <canvas id="waterTemp"></canvas>
                    </div>

                    <!-- TDS -->
                    <div class="col-span-1 bg-green-500 pl-2 pr-3 py-3 rounded-sm">
                        <div class="flex items-center justify-between">
                            <div>
                                <i class="icon-atom2" style="font-size: 35px"></i>
                            </div>
                            <div class="text-lg">TDS</div>
                        </div>
                        <div class="flex items-center justify-center my-5">
                            <div class="text-4xl font-semibold">{{loggers.tds.toFixed(2)}} ppm</div>
                        </div>
                        <div class="flex items-center text-gray-100">
                            <div class="font-medium">SAAT INI</div>
                            <div class="space-x-3.5 flex text-xs ml-auto">
                                <div class="border-b-2 border-gray-100">AVG: {{temp.avg}}</div>
                                <div class="border-b-2 border-gray-100">LOW: {{temp.low}}</div>
                                <div class="border-b-2 border-gray-100">HIGH: {{temp.high}}</div>
                            </div>
                        </div>
                        <canvas id="tds"></canvas>
                    </div>

                    <!-- HUMADITY -->
                    <div class="col-span-1 bg-yellow-500 pl-2 pr-3 py-3 rounded-sm">
                        <div class="flex items-center justify-between">
                            <div>
                                <i class="icon-droplets" style="font-size: 35px"></i>
                            </div>
                            <div class="text-lg">Humidity</div>
                        </div>
                        <div class="flex items-center justify-center my-5">
                            <div class="text-4xl font-semibold">{{loggers.hum.toFixed(2)}}</div>
                        </div>
                        <div class="flex items-center text-gray-100">
                            <div class="font-medium">SAAT INI</div>
                            <div class="space-x-3.5 flex text-xs ml-auto">
                                <div class="border-b-2 border-gray-100">AVG: {{temp.avg}}</div>
                                <div class="border-b-2 border-gray-100">LOW: {{temp.low}}</div>
                                <div class="border-b-2 border-gray-100">HIGH: {{temp.high}}</div>
                            </div>
                        </div>
                        <canvas id="hum"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Chart from 'chart.js/auto'
export default {
    data () {
        return {
            socket: '',
            device: false,
            temp: {
                logger: [],
                time: [],
                low: 0.00,
                high: 0.00,
                avg: 0.00
            },
            waterTemp: {
                logger: [],
                time: [],
                low: 0.00,
                high: 0.00,
                avg: 0.00
            },
            tds: {
                logger: [],
                time: [],
                low: 0.00,
                high: 0.00,
                avg: 0.00
            },
            hum: {
                logger: [],
                time: [],
                low: 0.00,
                high: 0.00,
                avg: 0.00
            },
            loggers: {
                temp: 0.00,
                waterTemp: 0.00,
                tds: 0.00,
                hum: 0.0,
                pompa: 0
            }
        }
    },
    mounted () {
        this.sockets.subscribe('data', (data) => {
            this.loggers = data
        });
        this.sockets.subscribe('client', (data) => {
            this.device = data
        })
        let ctxHeigh = 69
        let app = this
        const options = {
            responsive: true,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    backgroundColor: 'black',
                    displayColors: false,
                    titleAlign: 'center',
                    bodyAlign: 'center'
                }
            },
            layout: {
                padding: {
                    left: -1,
                    bottom: -4
                }
            },
        }
        const scalesGray = {
            scales: {
                x: {
                    labels: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                },
                y: {
                    ticks: {
                        display: true,
                        font: {
                            size: 10
                        },
                        color: 'rgb(113,113,122, 0.8)'
                    },
                    grid: {
                        display: true,
                        drawBorder: false,
                        color: 'rgb(113,113,122, 0.2)'
                    }
                }
            }
        }
        const scalesWhite = {
            scales: {
                x: {
                    labels: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                },
                y: {
                    ticks: {
                        display: true,
                        font: {
                            size: 10
                        },
                        color: 'rgb(244,244,245, 0.8)'
                    },
                    grid: {
                        display: true,
                        drawBorder: false,
                        color: 'rgb(244,244,245, 0.2)'
                    }
                }
            }
        }
        var ctxTemp = document.getElementById('temp')
        ctxTemp.height = ctxHeigh
        window.myTemp = new Chart(ctxTemp, {
            type: 'line',
            data: {
                labels: app.temp.time,
                datasets: [
                    {
                        data: app.temp.logger,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
        var ctxWaterTemp = document.getElementById('waterTemp')
        ctxWaterTemp.height = ctxHeigh
        window.myWaterTemp = new Chart(ctxWaterTemp, {
            type: 'line',
            data: {
                labels: app.waterTemp.time,
                datasets: [
                    {
                        data: app.waterTemp.logger,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesGray
            }
        })
        var ctxTds = document.getElementById('tds')
        ctxTds.height = ctxHeigh
        window.myTds = new Chart(ctxTds, {
            type: 'line',
            data: {
                labels: app.tds.time,
                datasets: [
                    {
                        data: app.tds.logger,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
        var ctxHum = document.getElementById('hum')
        ctxHum.height = ctxHeigh
        window.myHum = new Chart(ctxHum, {
            type: 'line',
            data: {
                labels: app.tds.time,
                datasets: [
                    {
                        data: app.tds.logger,
                        borderColor: 'rgb(250,250,250, 0.9)',
                        borderWidth: '1',
                        pointRadius: '0',
                        pointHoverRadius: '0',
                    }
                ]
            },
            options: {
                ...options,
                ...scalesWhite
            }
        })
    },
    destroyed () {
        this.sockets.unsubscribe('data')
        this.sockets.unsubscribe('client')
    },
    methods: {
         getTime () {
            let date = new Date()
            let hh = date.getHours()
            let mm = date.getMinutes()
            let ss = date.getSeconds()
            
            hh = checkTime(hh)
            mm = checkTime(mm)
            ss = checkTime(ss)

            function checkTime(i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }

            return `${hh}:${mm}:${ss}`
        },
        getAvg (arr) {
            let sum = 0
            let n = arr.length
            for (let i = 0; i < arr.length; i++) {
                sum += parseInt(arr[i])
            }
            return parseFloat(sum / n).toFixed(2)
        },
        
    },
    watch: {
        'loggers.temp': function (val) {
            if(val > 0) {
                let time = this.getTime()
                if(this.temp.logger.length > 60) {
                    this.temp.logger.splice(0, 1)
                }
                if(this.temp.time.length > 60) {
                    this.temp.time.splice(0, 1)
                }
                this.temp.logger.push(val.toFixed(2))
                this.temp.time.push(time)
                this.temp.low = Math.min(...this.temp.logger)
                this.temp.high = Math.max(...this.temp.logger)
                this.temp.avg = this.getAvg(this.temp.logger)
                window.myTemp.update()
            }
        },
        'loggers.waterTemp': function (val) {
            if(val > 0) {
                let time = this.getTime()
                if(this.waterTemp.logger.length > 60) {
                    this.waterTemp.logger.splice(0, 1)
                }
                if(this.waterTemp.time.length > 60) {
                    this.waterTemp.time.splice(0, 1)
                }
                this.waterTemp.logger.push(val.toFixed(2))
                this.waterTemp.time.push(time)
                this.waterTemp.low = Math.min(...this.waterTemp.logger)
                this.waterTemp.high = Math.max(...this.waterTemp.logger)
                this.waterTemp.avg = this.getAvg(this.waterTemp.logger)
                window.myWaterTemp.update()
            }
        },
        'loggers.tds': function (val) {
            if(val > 0) {
                let time = this.getTime()
                if(this.tds.logger.length > 60) {
                    this.tds.logger.splice(0, 1)
                }
                if(this.tds.time.length > 60) {
                    this.tds.time.splice(0, 1)
                }
                this.tds.logger.push(val.toFixed(2))
                this.tds.time.push(time)
                this.tds.low = Math.min(...this.tds.logger)
                this.tds.high = Math.max(...this.tds.logger)
                this.tds.avg = this.getAvg(this.tds.logger)
                window.myTds.update()
            }
        },
        'loggers.hum': function (val) {
            if(val > 0) {
                let time = this.getTime()
                if(this.hum.logger.length > 60) {
                    this.hum.logger.splice(0, 1)
                }
                if(this.hum.time.length > 60) {
                    this.hum.time.splice(0, 1)
                }
                this.hum.logger.push(val.toFixed(2))
                this.hum.time.push(time)
                this.hum.low = Math.min(...this.hum.logger)
                this.hum.high = Math.max(...this.hum.logger)
                this.hum.avg = this.getAvg(this.hum.logger)
                window.myHum.update()
            }
        },
        device (val) {
            if(!val) {
                this.loggers = {
                    temp: 0.00,
                    waterTemp: 0.00,
                    tds: 0.00,
                    hum: 0.0,
                    pompa: 0
                }
            }
        }
    }
}
</script>